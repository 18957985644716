import React, {useEffect, useRef, useState} from 'react';
import {AntDesign} from "@expo/vector-icons";
import * as ImagePicker from 'expo-image-picker';

import {Platform, StyleSheet, TouchableOpacity} from 'react-native';
import {
  Pressable, View, Image, Text, Button, Center, ScrollView,
  Box, Icon, Checkbox, Divider, FormControl, Input, useColorMode
} from "native-base";
import apiClient from "../../../utils/apiClient";
import {localInfo, translate} from "../../../utils/Translate";
import SelectList from "../../../components/SelectList";
import {getBase64File} from "../../../utils/FileTools";
import CountryStatesSelectList from "../../../components/CountryStatesSelectList";

export default function CompanyInfo({ route, navigation }) {

  const { userData } = route.params;

  const [isTheOwner, setIsTheOwner] = useState(false)
  const [selectedState, setSelectedState] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([])
  const [branches, setBranches] = useState([])
  const [commercialRegisterFront, setCommercialRegisterFront] = useState(null);
  const [commercialRegisterBack, setCommercialRegisterBack] = useState(null);
  const [startupLabel, setStartupLabel] = useState(null);
  const [errors, setErrors] = React.useState({});
  const [isStartup, setIsStartup] = React.useState(false);

  const webCommercialRegisterFront = useRef();
  const webCommercialRegisterBack = useRef();
  const webStartupLabel = useRef();
  const countryId = useRef(4);

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  const pickLabelImage = async () => {
    let image = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (image.canceled) return

    if (Platform.OS === "web"){
      getBase64File(image.assets[0].uri, 'startup-label').then((file) => webStartupLabel.current = file)
    }

    setStartupLabel(image.assets[0]);
    setErrors({ ...errors,
      startupLabel: null
    });
  };

  let onStateChanged = (stateID, countryID) => {
    setErrors({ ...errors,
      state: null
    });

    countryId.current = countryID
    setSelectedState(stateID)
  }

  let onBranchChanged = (item) => {
    setErrors({ ...errors,
      branches: null
    });

    setSelectedBranches(item)
  }

  let handleJobTitleInput = text => {
    userData.user.company_role = text
    if (userData.user.company_role.length < 2){
      setErrors({ ...errors,
        role: null
      });
    }
  };

  function getBranches(){
    apiClient.get(`branches`).then(response => {
      setBranchesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error.response.data));
  }

  function setBranchesOfLanguage(branches, language){
    let getBranches = branches.map(branch => ({
      id: branch.id,
      name: language === 'ar' ? branch.name.ar :
        language === 'fr' ? branch.name.fr : branch.name.en
    }))

    setBranches(getBranches)
  }

  const handleNextButton = () => {
    if (!validate()){
      return
    }

    userData.companyBranches    = selectedBranches;
    userData.company.state_id   = selectedState;
    userData.company.country_id = countryId.current;
    userData.company.is_startup = isStartup;

    if (!!isStartup && startupLabel !== null) {
      userData.startupLabel = {
        uri: startupLabel.uri,
        type: `${startupLabel.type}/${getFileType(startupLabel)}`,
        name: getFileName(startupLabel)
      }
    }

    // For web
    if (Platform.OS === "web"){

      if (webStartupLabel !== null) {
        userData.webStartupLabel = webStartupLabel.current;
      }
    }

    navigation.navigate('InterestedBranches', {userData})
  }

  const validate = () => {
    if (selectedState.length === 0) {
      setErrors({ ...errors,
        state: translate('validation.state')
      });
      return false;
    } else if (selectedBranches.length === 0) {
      setErrors({ ...errors,
        branches: translate('validation.branches')
      });
      return false;
    } else if (!!isStartup && startupLabel === null) {
      setErrors({ ...errors,
        startupLabel: translate('validation.startupLabel')
      });
      return false;
    } else if (userData.user.company_role === null) {
      setErrors({ ...errors,
        role: translate('validation.role')
      });
      return false;
    } else if (userData.user.company_role.length < 2) {
      setErrors({ ...errors,
        role: translate('validation.role')
      });
      return false;
    }

    return true;
  };

  function getFileName(file){
    return file.uri.substring(file.uri.lastIndexOf("/") + 1);
  }
  function getFileType(file){
    return file.uri.substring(file.uri.lastIndexOf(".") + 1);
  }

  useEffect(() => {
    getBranches()
  }, [])

  const styles = StyleSheet.create({
    contentContainerStyle: {
      justifyContent: "space-between",
      flexDirection: "column",
      backgroundColor: isDark ? "#1f2937" :'#fff',
      flexGrow: 1
    },
  })

  const handleIsStartup = (value) => {
    setIsStartup(value)
  }

  return (
    <ScrollView
      px={[0, 0, 32, 64]}
      contentContainerStyle={styles.contentContainerStyle}
    >
        <View mt={12}>
          <Pressable
            m={5}
            onPress={() => navigation.goBack()}>
            <Image
              alt="back icon"
              source={require('../../../assets/icons/back_arrow.png')}
            />
          </Pressable>

          <View mt={6}>
            <Box ml={4} mr={10}>
              <View alignItems='flex-end' flexDirection="row">
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="24">3/4 </Text>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mb={1}>
                  {translate('misc.complete')}
                </Text>
              </View>

              <View mt={2} flexDirection="row">
                <View m={1} h={1} w="25%" bg="appPrimary"></View>
                <View m={1} h={1} w="25%" bg="appPrimary"></View>
                <View m={1} h={1} w="25%" bg="appPrimary"></View>
                <View m={1} h={1} w="25%" bg="appSecondary"></View>
              </View>
            </Box>

            <View ml={4} mt={8}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="24" fontWeight={500}>
                {translate('screens.auth.companyInfo.CNRCInfo')}
              </Text>

              <View mt={4} mr={4}>
                <CountryStatesSelectList
                  selectText={translate('screens.auth.interests.selectCountryState')}
                  modalHeaderText={translate('screens.auth.interests.selectCountryState')}
                  doneText={translate('misc.done')}
                  cancelText={translate('misc.cancel')}
                  isMultiSelect={false}
                  onDone={onStateChanged}
                />
                {
                  'state' in errors ? (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary">
                      {errors.state}
                    </Text>
                  ) : null
                }
              </View>

              {
                branches.length ? (
                  <View mt={1} mr={4}>
                    <SelectList
                      selectText={translate('misc.branches')}
                      modalHeaderText={translate('screens.auth.interests.selectBranches')}
                      doneText={translate('misc.done')}
                      cancelText={translate('misc.cancel')}
                      onDone={onBranchChanged}
                      items={branches}
                    />
                    {
                      'branches' in errors ? (
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary">
                          {errors.branches}
                        </Text>
                      ) : null
                    }
                  </View>
                ) : null
              }

            </View>

            <View mt={9} mb={3} mx="8">
              <Divider thickness="1" bg="appPrimary" />
            </View>

            <View mt={2} px={3}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={21} fontWeight={500}>
                {translate('screens.auth.companyInfo.areYouStartup')}
              </Text>
              <Checkbox
                shadow={2} my={1} mt={2}
                accessibilityLabel="Are you a Startup?"
                onChange={handleIsStartup}
              >
                <View>
                  <Text
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="18" ml={2}
                    color={ isTheOwner ? "appSecondary" : isDark ? "white" : "black" }>
                    {translate('screens.auth.companyInfo.yesStartup')}
                  </Text>
                </View>
              </Checkbox>

              <View mt={3}/>
              {
                isStartup ? (
                  <Box borderWidth={1} borderRadius={10} p={5} w="45%">
                    <TouchableOpacity onPress={pickLabelImage}>
                      <Center>
                        {
                          !startupLabel ? (
                            <Center>
                              <Icon as={<AntDesign name="profile" />} size={16} />
                              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={3} fontSize={17}>
                                {translate('screens.auth.companyInfo.uploadLabel')}
                              </Text>
                            </Center>
                          ) : (
                            <Center>
                              <Icon as={<AntDesign name="checkcircleo" />} size={16} color="appPrimary" />
                              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={3} fontSize={17}>
                                {translate('screens.auth.companyInfo.label')}
                              </Text>
                            </Center>

                          )
                        }
                        {
                          'startupLabel' in errors ? (
                            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary">
                              {errors.startupLabel}
                            </Text>
                          ) : null
                        }
                      </Center>
                    </TouchableOpacity>
                  </Box>
                ) : null
              }
            </View>

            <View mt={6} mb={3} mx="8">
              <Divider thickness="1" bg="appPrimary" />
            </View>


            <View pl={3}>
              <Checkbox
                colorScheme="danger"
                mt={4} shadow={2}
                value={isTheOwner}
                accessibilityLabel="I’m not the owner"
                onChange={ (val) => setIsTheOwner(val) }
              >
                <View>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="22" ml={2}
                        color={ isTheOwner ? "appSecondary" : isDark ? "white" : "black" }>
                    {translate('screens.auth.companyInfo.notOwner')}
                  </Text>
                </View>
              </Checkbox>
            </View>


            <Center mt={3}>

              <FormControl mt={4} w="90%">
                <Input
                  textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  placeholder={translate('screens.auth.companyInfo.yourJobTitle')}
                  rounded={10}
                  size="xl" borderColor="gray.500"
                  pl={4} py={4}
                  onChangeText={handleJobTitleInput}
                />
              </FormControl>
              {
                'role' in errors ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary">
                    {errors.role}
                  </Text>
                ) : null
              }
            </Center>

            <View my="9"/>
          </View>
        </View>

      <Center mb={16}>
        <Button
          w="85%"
          onPress={handleNextButton}
          size="lg"
          color="appPrimary"
          borderRadius={8}
        >
          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
            {translate('misc.next')}
          </Text>
        </Button>
      </Center>

      <View my="32"/>
    </ScrollView>
  );
}
