import React, {useEffect, useRef, useState} from 'react';
import {FontAwesome, MaterialCommunityIcons} from "@expo/vector-icons";
import {Chat, darkTheme, defaultTheme} from '@flyerhq/react-native-chat-ui'
import apiClient from "../utils/apiClient";
import UserKeyManager from "../utils/UserKeyManager";
import Pusher from 'pusher-js';
import {BROADCASTING_AUTH, PUSHER_APP_CLUSTER, PUSHER_APP_KEY} from "../environment"
import { Crypt } from 'hybrid-crypto-js';

import {Icon, useColorMode, useTheme, View} from "native-base";
import {translate} from "../utils/Translate";
import * as ImagePicker from "expo-image-picker";
import {Platform} from "react-native";
import SubscriptionExpiredModal from "../components/SubscriptionExpiredModal";
import AlertModal from "../components/AlertModal";
import AuthUserManager from "../utils/AuthUserManager";

export default ({ route }) => {

  const { type, typeId, currentSenderId, token, currentConversation, isSupportChat } = route.params;

  const {colorMode} = useColorMode();
  const isDark = colorMode === 'dark';

  let crypt = new Crypt({
    aesKeySize: 192,
  });

  const pusher = React.useMemo(() => {
    return new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
      authEndpoint: BROADCASTING_AUTH,
      auth: {
        headers: {
          Authorization: 'Bearer ' + token
        },
      },
    })
  }, [] )

  const [messages, setMessages] = useState([])
  const [conversation, setConversation] = useState(0)
  const [message, setMessage] = useState(null)
  const [showSubscriptionExpired, setShowSubscriptionExpired] = useState(false)
  const [canSendMessage, setCanSendMessage] = useState(true)
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [alertMsg, setAlertMsg] = useState("");

  const nextPage = useRef('')
  const isLoadingMoreRef = useRef(false)
  const userPrivateKey = useRef('')
  const recipientPublicKeys = useRef([])
  const currentUserPublicKeys = useRef([])
  const accountNotVerified = useRef(false);

  const { colors } = useTheme();

  const handleSendPress = (message) => {
    sendMessage(message.text)
  }

  function getConversation(isSupport = false){

    const url = isSupport ? `support` : `users/${type}/${typeId}`

    return new Promise((resolve, reject) => {
      apiClient.get(`conversations/${conversation}/${url}`).then((response) => {
        if (!!response.data.id){
          resolve(response.data.id)
        } else {
          resolve(null)
        }
      }).catch(error => reject(error.response.data))
    })
  }

  function getMessages(conv, isLoadMore){

    let nextP = nextPage.current.length ? `?${nextPage.current}` : '';

    apiClient.get(`conversations/${conv}/messages${nextP}`).then((response) => {

      if (isLoadMore && nextPage.current.length && !!response.data.data){
        getNextPage(response.data.next_page_url)
        let newMessages = transformMessages(response.data.data)
        setMessages(old => [...old, ...newMessages])

        isLoadingMoreRef.current = false
        return;
      }

      if (!!response.data.data){
        getNextPage(response.data.next_page_url)
        setMessages(transformMessages(response.data.data))
      }
    }).catch(error => console.log(error.response.data))
  }

  function markAsRead(messageId){
    apiClient.post(`messages/read/${messageId}`).then()
      .catch(error => console.log(error.response.data))
  }

  function sendMessage(messageToSend, images){

    // If this is a demo account return alert message
    if (currentUser.id === 10193){
      setAlertMsg(translate('demoAlert.message'))
      setShowAlertModal(true);
      return;
    }
    if (!!accountNotVerified.current && !isSupportChat) {
      setAlertMsg(translate('validation.accountNotVerified'));
      setShowAlertModal(true);
      return;
    }

    if (!isSupportChat && !canSendMessage){
      setShowSubscriptionExpired(true)
      return;
    }

    if (!isSupportChat && !!messageToSend && !!recipientPublicKeys.current.length && !!currentUserPublicKeys.current.length){
      const keys = [...recipientPublicKeys.current, ...currentUserPublicKeys.current];
      messageToSend = crypt.encrypt(keys, messageToSend);
    }

    const data = new FormData()

    data.append('data', JSON.stringify({
      toType: type, toTypeId: typeId, message: messageToSend
    }))

    if (!!images && images.length){
      for (let n = 0; n < images.length; n++){
        data.append('images[]', images[n])
      }
    }

    apiClient.post(`conversations/${conversation}/messages`, data, {isFormData: true})
      .then((response) => {
        if (!!response.data.conversation_id && conversation === 0 && !!currentConversation === false){

          setConversation(response.data.conversation_id)

          if (response.data.type === 'image'){
            getMessages(response.data.conversation_id)
            subscribeToChannel(response.data.conversation_id)

            return;
          }

          let responseMessage = response.data.type === 'text' ? decryptMessage(response.data.body) : response.data.body

          let message = {
            author: { id: response.data.sender.id },
            createdAt: response.data.created_at,
            id: response.data.id,
            text: responseMessage,
            type: response.data.type,
          }

          setMessages(oldArray => [message, ...oldArray]);

          subscribeToChannel(response.data.conversation_id)
        }
    }).catch(e => console.log(e.response.data))
  }

  function transformMessages(messages){
    return messages.map((msg) =>
      {
        let senderName = !!msg.sender.name ? msg.sender.name : msg.sender.full_name
        if (senderName === "[deleted]"){
          senderName = translate('misc.deletedUser')
        }

        let responseMessage = msg.type === 'text' ? decryptMessage(msg.body) : null;

        if (msg.type === 'text' && decryptMessage(msg.body) === null){
          return null;
        }

        return {
          author: { id: msg.sender.id, firstName: senderName },
          createdAt: msg.created_at,
          id: msg.id,
          text: responseMessage,
          type: msg.type,
          uri: msg.type === 'image' ? msg.body : null,
        }
      }
    ).filter(Boolean);
  }

  function subscribeToChannel(conv){
    const channel = pusher.subscribe(`private-mc-chat-conversation.${conv}`);

    channel.bind('pusher:subscription_succeeded', function() {
      console.log("pusher subscription succeeded")
    });
    channel.bind('pusher:subscription_error', function(error) {
      console.log(error.error)
    });

    channel.bind("Musonza\\Chat\\Eventing\\MessageWasSent", function (data) {

      markAsRead(data.message.id);

      let responseMessage = data.message.type === 'text' ? decryptMessage(data.message.body) : null;

      let message = {
        author: { id: data.message.sender.id },
        createdAt: data.message.created_at,
        id: data.message.id,
        text: responseMessage,
        type: data.message.type,
        uri: data.message.type === 'image' ? data.message.body : null,
      }

      setMessages(oldArray => [message, ...oldArray]);
    });
  }

  function canAccessSubscriptionFeature() {
    apiClient.get(`subscription/check-feature?feature=chat-message`).then((res) => {
      setCanSendMessage(res.data)
    })
  }

  useEffect(() => {
    const initUserKey = async () => {
      // Get current user
      let auth = await AuthUserManager.get()

      await checkUserEligibility(auth)

      setCurrentUser(auth)

      const hasPKey = await UserKeyManager.hasPrivateKey();
      if (hasPKey){
        userPrivateKey.current = await UserKeyManager.getPrivateKey();
      } else {
        await UserKeyManager.storePrivateKey()
        userPrivateKey.current = await UserKeyManager.getPrivateKey();
      }

      if (!isSupportChat){
        apiClient.get(`keys?recipient=${type}&recipientId=${typeId}`).then(res => {
          recipientPublicKeys.current = res.data;
        }).catch(e => console.log(e.response.data))
      }

      apiClient.get(`keys/me`).then(res => {
        currentUserPublicKeys.current = res.data;
      }).catch(e => console.log(e.response.data))
    }

    initUserKey().catch(console.log)

    canAccessSubscriptionFeature()

    if (!!currentConversation === false){
      getConversation(!!isSupportChat).then((conv) => {
        if (!!conv){
          setConversation(conv)
          getMessages(conv)
          subscribeToChannel(conv)
        }
      })
    } else {
      subscribeToChannel(currentConversation)
      getMessages(currentConversation)
    }

    return function cleanup () {
      pusher.disconnect();
    }
  }, [])

  const chatTheme = isDark ? darkTheme : defaultTheme;

  const handleImageSelection =  async () => {

    if (!isSupportChat && !canSendMessage){
      setShowSubscriptionExpired(true)
      return;
    }

    let image = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images
    });

    if (image.cancelled) return

    if(Platform.OS === 'web'){
      const imagesUri = image.selected;

      let images = [];

      for (let imgUri of imagesUri){
        images = [...images, imgUri.uri]
      }

      sendMessage(null, images);
      return;
    }

    let img = {
      uri: image.assets[0].uri,
      type: `${image.assets[0].type}/${getFileType(image.assets[0])}`,
      name: getFileName(image.assets[0])
    }

    sendMessage(null, [img]);
  }

  function getFileName(file){
    return file.uri.substring(file.uri.lastIndexOf("/") + 1);
  }
  function getFileType(file){
    return file.uri.substring(file.uri.lastIndexOf(".") + 1);
  }

  // const handleChatText = (text) => {
  //   console.log('message', message)
  //   setMessage(text)
  // }
  //
  // const handleChatKeysPress = (e) => {
  //   if (e.key === "Enter" && !!message && message.length > 1 ){
  //     sendMessage(message)
  //     setMessage(null)
  //   }
  // }

  const handleLoadMore = () => {
    if (isLoadingMoreRef.current || nextPage.current.length === 0) return;

    let theConversation  = !!currentConversation ? currentConversation : conversation;

    isLoadingMoreRef.current = true
    getMessages(theConversation, true)
  }

  function getNextPage(nextPageUrl){
    if (!!nextPageUrl){
      nextPage.current = nextPageUrl.match('page=[0-9]*')[0];
    } else {
      nextPage.current = ''
    }
  }

  function decryptMessage(messageToDecrypt){

    if (isJSON(messageToDecrypt)){
      try {
        const decryptedMessage = crypt.decrypt(userPrivateKey.current, messageToDecrypt);
        return decryptedMessage.message
      } catch (e){
        return null;
      }
    }

    return messageToDecrypt
  }

  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  async function checkUserEligibility(cUser) {
    let user = cUser;

    if (!!user.company_id && user.company_status !== "verified" && !isSupportChat){
      setAlertMsg(translate('validation.accountNotVerified'));
      setShowAlertModal(true);
      accountNotVerified.current = true;
    }
  }

  return (

    <View w="100%" h="100%">
      {/* Check user subscription */}
      {
        showSubscriptionExpired ? (
          <SubscriptionExpiredModal featureToAccess={"chat-message"}/>
        ) : null
      }

      <AlertModal alertText={alertMsg} isOpen={showAlertModal} onClose={() => setShowAlertModal(false)}/>

      <Chat
        showUserNames={true}
        messages={messages}
        user={{id: currentSenderId}}
        onSendPress={handleSendPress}
        onAttachmentPress={handleImageSelection}
        onEndReached={handleLoadMore}
        textInputProps={{
          // onKeyPress: handleChatKeysPress,
          // onChangeText: handleChatText,
          // value: message
        }}
        l10nOverride={{ inputPlaceholder: translate('screens.chat.message') }}
        theme={{
          ...chatTheme,
          colors: { ...chatTheme.colors,
            inputBackground: colors.appPrimary,
            primary: colors.black['800'],
            ...(isDark && {secondary: colors.appPrimary}),
            ...(isDark && {background: '#1f2937'})
          },
          icons: {
            sendButtonIcon: () => (
              <Icon as={<FontAwesome name="paper-plane"/>} color="primary.50" size={5} />
            ),
            attachmentButtonIcon: () => (
              <Icon as={<MaterialCommunityIcons name="camera"/>} color="primary.50" size={7} mr={4} />
            )
          }
        }}
      />
    </View>

  );
}
